<template>
  <section class="page-container">
    <lazy-dom>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
      >
        <el-row
          :gutter="40"
          justify="space-around">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="18"
            :xl="19"
            class="page-content-and-up"
          >
            <div class="flex flex-justify">
              <div
                class="header-title-back flex"
                @click="() => $router.back()"
              >
                <i class="el-icon-arrow-left"></i>
                <span>
                  Back
                </span>
              </div>
              <div v-if="draft">
                <v-button
                  class="button-delete"
                  @click="handlerDelete">
                  <font-awesome-icon
                    icon="trash"
                    class="margin-right-five"></font-awesome-icon>
                  <span>Delete</span></v-button>
              </div>
            </div>
            <home-form
              ref="motorForm"
              @handlerCompanyId="handlerCompanyId"
              @handlerClassId="handlerClassId"
              @getFormData="getSubFormData"></home-form>
          </el-col>
          <el-col
            class="padding-none-sm-and-down  page-content-auto"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="5"
            :xl="5">
            <div class="page-content-fixed">
              <discount-detail
                ref="discount-detail"
                :isNext="false"
                :isSave="is_save"
                :insurer="insurer"
                :form="formData.premium"
                @next="hanlderNext"
                @draft="handlerDraft"
                @getFormData="getSubFormData"
              ></discount-detail>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </lazy-dom>
  </section>
</template>

<script>
import homeForm from '@/views/quotation/home/homeForm'
import discountDetail from '@/views/components/discountDetail/discountDetail'
import {saveQuotationHome} from '@/api/quotation'
import {deleteOrder,getOrderDetail} from '@/api/order'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
import MixinOrder from '@/mixins/order'
export default {
  name: 'homeInsurance',
  components: {
    homeForm,
    discountDetail,
  },
  mixins: [MixinRules,MixinOrder],
  data() {
    return {
      ncdShow:0,
      order_no:'',
      carTypeName: 'Private Car',
      clientType: 1,
      isNext: false,
      cover_type: 1,
      insurer:0,
      policyOneDriver:false,
      subForm: {
        'motorForm':false,
        'discount-detail':false,
      },
      formData: {
        draft:false,
        product_id: 13,//r
        ////policy detail
        // policy_type: 200,//r
        // company_id: 0,//r
        // product_id: 10,//r
        // class_id: 1001,//r CLASS_PRIVATE_CAR = 1001|CLASS_COMMERCIAL_VEHICLE = 1002|CLASS_MOTORCYCLE = 1003|CLASS_MOTOR_TRADE = 1004
        // client_type: 1,//r
        // issue_date: '',
        // effective_date: '',//r

        /////policyholder
        // policy_holder_name: '',//r
        // policy_holder_mobile: '',//r
        // policy_holder_email: '',
        // policy_holder_id_no: '',
        // policy_holder_id_type: '',//1-hkid 2-passport 3-br
        // policy_holder_occ_id: '',
        // policy_holder_birth: '',
        // correspondence_address: {
        //   room: '',
        //   floor: '',
        //   block: '',
        //   building: '',
        //   street: '',
        //   district: '',
        //   area: '',
        //   region: ''
        // },

        // base_premium: '',
        // loading: '',
        // client_discount: '',
        // additional_premium: '',
        // ia_levy_vl: '',
        // amount_received: '',
        // special_discount: '',
        // net: '',
        // insurer_net: '',
        // is_special: 1,//special_discount/amount_received只能使用其中一個。1-special_discount 2 - amount_received,


        coverage: {
          cover_type: 1, //1 third party 2 comprehensive
          hire_purchase_owner: 1, //1 yes,no-2
          hire_purchase_owner_id: '',
          estimated_vehicle_value: 0,
          policy_send_to_hire_purchase_owner_date: '',
        },
        // excess: {
        //   own_damage: 0,
        //   parking: 0,
        //   unnamed_driver: 0,
        //   young_driver: 0,
        //   inexperienced_driver: 0,
        //   theft: 0,
        //   tppd: 0,
        //   tppd_young_driver: 0,
        //   tppd_inexperienced_driver: 0,
        //   tppd_unnamed_driver: 0
        // },
        // vehicle_detail: {
        //   registration_mark: '',
        //   searing_capacity: '',
        //   car_make_id: '',
        //   chassis_number: '',
        //   car_model: '',
        //   body_type_id: '',//i
        //   engine_number: '',
        //   ncd: '',//i,
        //   vehicle_cylinder_capacity: '',//i
        //   year_of_manufacture: '',
        // },
        // driver: [
        //   {
        //     name: '',
        //     occ_id: '',//i
        //     birth: '',
        //     driver_experience_id: '',//1:less than 1 years /2: 1-2  years /3: 2-5 years/4: more than 5 years
        //     id_type: 1,//1-hkid 2-passport,
        //     id_no: '',
        //     relationship_id: '',//1 Parent /2 Child /3 Spouse / 4 Relative /5 Friend /6 Employee /7 Employer
        //   }
        // ],
        remark: '',
        // policy_no: '',
        // covernote_no: '',
        // policy_document_file_id: '',
        // covernote_document_file_id: '',
        // policy_receive_date: '',
        // policy_dispatch_date: '',
        // policy_dispatch_mathed_id: '',//i //1 Collect /2 Mail /3 Keep in office /4 Other
        // required_documents: [],
        // document:[{file_id:''}]
      }
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        return is_save
      }return  false
    },
    draft(){
      return this.$route.query.isDraft=='true'
    }
  },
  watch:{
    quoteInformationForm:{
      deep:true,
      immediate:true,
      handler(val){
        let order_no = this.$route.query.order_no||null
        if(!val&&order_no!=null){
          this.network().getOrderDetail({order_no})
        }

      }
    }
  },
  created() {
    this.handlerInit()
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    handlerInit(){
      let {order_no='',class_id} = this.quoteInformationForm||{}
      this.formData =this.quoteInformationForm||{}
      this.order_no = order_no
    },
    handlerCompanyId(val){
      this.insurer =val
    },
    handlerClassId(val){
      this.carTypeName = val
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerBack() {
      this.$router.back()
    },
    submitData(data,draft){
      this.formData = Object.assign(this.formData, data)
    },
    handlerValidate() {
      // this.$refs.motorForm.handlerValidate()
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }

      if(this.is_draft)
      {

        this.network().draftQuotation()

      }else{
        this.network().saveQuotation()
      }

    },
    hanlderNext(order_no) {
      this.is_draft = false
      this.handlerValidate()
    },
    handlerDraft(){
      this.is_draft =true
      this.handlerValidate()
    },
    // handlerDelete(){
    //   this.network().deleteOrder()
    // },
    network() {
      return {
        saveQuotation: async () => {
          let params = {
            is_edit:this.is_edit,
            ...this.formData
          }
          let { data } = await saveQuotationHome(params)
          let { order_no } =data
          this.isNext = true
          if(this.is_save){
            this.$router.back()
          }else{
            this.$router.push({
              name: 'info',
              query:{
                order_no,
                isEndorsement:false}
            })
          }
        },
        //草稿单
        draftQuotation: async () => {
          this.is_draft =true
          let params ={
            is_draft:1,
            ...this.formData,
            order_no:this.order_no||'',
            is_edit:this.is_edit,
          }
          let { data } = await saveQuotationHome(params)
          let { order_no } =data
          this.order_no = order_no
          this.formData = Object.assign(this.formData,{order_no:order_no})
          this.setQuoteInformationForm(this.formData)
          this.$ConfirmBox({
            title: '',
            message: 'Save Draft',
            confirmBtn:'OK',
            isCancel:false
          }).then(() => {
          })
        },
        deleteOrder: async () => {
          let params ={
            order_no:this.order_no
          }
          let { data } = await deleteOrder(params)
          this.setQuoteInformationForm(null)
        },
        getOrderDetail: async ({order_no}) => {
          let { data } = await getOrderDetail({order_no})
          let params ={
            order_no,
            ...data.data,
          }
          this.setProductID(params.product_id)
          this.setQuoteInformationForm(params)
          this.handlerInit()
        },

      }
    }
  }
}
</script>
